import React from "react"

import { useFirebase } from "gatsby-plugin-firebase"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Footer from "../components/sections/footer"

import styled from "styled-components"
import { Container } from "../components/global"

const ReceiptsPage = () => {
    
    useFirebase(firebase => { firebase.analytics().logEvent("page_view_receipts") }, [])

    return (
    <Layout>
        <SEO title="apocha" />
        <Navigation />
        <ReceiptsContainer>
            
        </ReceiptsContainer>
        <Footer />
    </Layout>
)
}

export default ReceiptsPage

const ReceiptsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 80px 18px 40px;
`
